<template>
  <v-row class="thread-container">
    <v-col cols="12 pb-0">
      <div class="d-flex align-center justify-space-between">
        <span class="ps-5">{{ count }} Bình luận</span>
        <Profile />
      </div>
    </v-col>
    <v-col
      cols="12"
      class="py-0"
    >
      <Comment
        :key="thread.id"
        :comment="thread"
        :depth="0"
        :path="[]"
      />
    </v-col>
  </v-row>
</template>

<script>

import Profile from '../components/Profile.vue'
import Comment from '../components/Comment.vue'

export default {
  components: {
    Profile,
    Comment,
  },

  computed: {
    thread() {
      return this.$store.getters['embed/thread'] || {}
    },
    count() {
      let count = 0
      function countRecursive(parent) {
        const comments = parent.comments || []
        if (comments.length === 0) return

        count += comments.length

        comments.forEach(comment => { countRecursive(comment) })
      }

      countRecursive(this.thread)

      return count
    },
  },

  created() {
    this.$store.commit('embed/SET_ARTICLE', this.$route.params.id)
    this.$store.dispatch('embed/FETCH_COMMENT')
  },
}
</script>

<style scoped>
  .thread-container {
    margin-left: -32px;
    margin-right: -30px;
  }
</style>
