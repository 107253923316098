<template>
  <v-card
    elevation="0"
    tile
    :style="branch"
  >
    <v-card-title v-if="depth > 0">
      <v-avatar
        size="45px"
        left
        :color="comment.author.color"
      >
        <span class="white--text text-subtitle-1">{{ comment.author.avatar }}</span>
      </v-avatar>
      <div class="d-flex flex-column ps-2 mr-auto">
        <span class="text-subtitle-1">
          {{ comment.author.name }}
          <v-chip
            v-if="comment.author.is_admin"
            small
            color="warning"
          >
            <span style="color:#000;">Quản trị viên</span>
          </v-chip>
        </span>
        <span class="caption">{{ created_at }}</span>
      </div>
      <v-menu
        v-if="editable"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
            >
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            link
            @click="editing=true"
          >
            <v-icon
              small
              left
            >
              mdi-pencil
            </v-icon>
            <span>Chỉnh sửa</span>
          </v-list-item>
          <v-list-item
            link
            @click="deleteComment"
          >
            <v-list-item-title>
              <v-icon
                small
                left
              >
                mdi-delete
              </v-icon>
              <span>Xóa</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-card-text v-if="depth > 0">
      <!-- Update comment -->
      <CommentUpsert
        v-if="editing"
        :id="comment.id"
        :content="comment.content"
        :path="path"
        @saved="editing=false"
      />
      <!-- Show comment -->
      <!-- eslint-disable -->
      <div
        v-else
        class="text-justify"
        v-html="sanitize(comment.content)"
      />
      <!-- eslint-enable -->
      <div
        v-if="depth < 5"
        class="d-flex"
      >
        <v-btn
          class="ml-auto"
          small
          text
          @click="creating=!creating"
        >
          <v-icon small>
            mdi-reply
          </v-icon>
          <span>Trả lời</span>
        </v-btn>
      </div>
    </v-card-text>

    <v-card-text v-if="depth === 0 || creating">
      <CommentUpsert
        :class="depth > 0 ? 'ml-8' : ''"
        :path="path"
        :clear-after-submit="true"
        @saved="creating=false"
      />
    </v-card-text>

    <Comment
      v-for="cm in comment.comments"
      :key="cm.id"
      :class="depth > 0 ? 'ml-12' : ''"
      :parent-id="cm.id"
      :comment="cm"
      :depth="depth+1"
      :path="[...path, cm.id]"
    />
  </v-card>
</template>

<script>

import dayjs from 'dayjs'
import CommentUpsert from './CommentUpsert.vue'

export default {
  name: 'Comment',
  components: {
    CommentUpsert,
  },
  props: {
    depth: {
      type: Number,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      creating: false,
    }
  },
  computed: {
    branch() {
      if (this.depth === 0) return {}

      return { borderLeft: '3px solid rgba(58, 53, 65, 0.2)' }
    },
    user() {
      return this.$store.getters['user/user'] || false
    },
    editable() {
      return this.user && (this.user.id === this.comment.author.id)
    },
    created_at() {
      return dayjs(this.comment.created_at).format('DD/MM/YYYY HH:mm')
    },
  },
  methods: {
    sanitize(value) {
      return this.$sanitize(value, { allowedTags: ['a', 'p'] })
    },
    deleteComment() {
      this.$store.dispatch('embed/DELETE_COMMENT', { path: this.path })
    },
  },
}
</script>
