<template>
  <v-card
    outlined
    flat
    class="d-flex justify-space-between"
  >
    <tiptap-vuetify
      v-model="currentContent"
      :extensions="extensions"
      style="width: 100%"
      placeholder="Nhập bình luận"
    >
      <template #toolbar="{ }">
        <div></div>
      </template>
    </tiptap-vuetify>
    <v-btn
      style="height: 41px !important"
      icon
      @click="submit"
    >
      <v-icon>
        mdi-send
      </v-icon>
    </v-btn>
  </v-card>
</template>

<script>

import { TiptapVuetify, Link } from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  props: {
    id: {
      type: String,
      default: null,
    },
    path: {
      type: Array,
      default: () => [],
    },
    content: {
      type: String,
      default: '',
    },
    clearAfterSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentContent: this.content,
      extensions: [Link],
    }
  },
  methods: {
    submit() {
      if (this.currentContent === '') return

      if (!this.$store.getters['auth/isAuthenticated']) {
        const width = 425
        const height = 425
        // eslint-disable-next-line no-restricted-globals
        const left = (screen.width - width) / 2
        // eslint-disable-next-line no-restricted-globals
        const top = (screen.height - height) / 3

        window.open('/embed/login', '_blank', `
          scrollbars=0,
          width=${width},
          height=${height},
          top=${top},
          left=${left}
        `)

        return
      }

      if (this.id !== null) {
        this.updateComment()
      } else {
        this.createComment()
      }

      this.$store.dispatch('embed/FETCH_COMMENT')

      if (this.clearAfterSubmit) {
        this.currentContent = ''
      }
      this.$emit('saved')
    },

    createComment() {
      const payload = {
        path: this.path,
        content: this.currentContent,
      }
      this.$store.dispatch('embed/CREATE_COMMENT', payload)
    },

    updateComment() {
      const payload = {
        id: this.id,
        path: this.path,
        content: this.currentContent,
      }
      this.$store.dispatch('embed/UPDATE_COMMENT', payload)
    },

  },
}
</script>

<style scoped>
::v-deep .tiptap-vuetify-editor .v-card {
  box-shadow: none!important;;
}
::v-deep .tiptap-vuetify-editor__content .ProseMirror {
  margin: 5px 15px!important;
}
::v-deep .tiptap-vuetify-editor__content .ProseMirror p {
  margin: 5px 0px!important;
}
</style>
