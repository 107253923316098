<template>
  <div v-if="user">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="me-5 pa-2"
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ user.name }}
          <v-icon
            small
            right
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          link
          to="/setting"
          target="_blank"
        >
          <v-list-item-title>
            <v-icon
              small
              left
              class="mb-1"
            >
              mdi-cog
            </v-icon>
            Cài đặt
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-title>
            <v-icon
              small
              left
              class="mb-1"
            >
              mdi-logout
            </v-icon>
            Đăng xuất
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  <v-btn
    v-else
    text
    class="me-5 pa-2"
    @click="openLoginWindow"
  >
    Đăng nhập
  </v-btn>
</template>

<script>

export default {
  computed: {
    user() {
      return this.$store.getters['user/user'] || false
    },
  },

  created() {
    const channel = new BroadcastChannel('logged-in')
    channel.onmessage = event => {
      this.login(event)
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/LOGOUT', false)
    },
    login(event) {
      const { token, user } = event.data
      this.$store.commit('auth/SET_TOKEN', token)
      this.$store.commit('user/SET_USER', user)
    },
    openLoginWindow() {
      const width = 425
      const height = 425
      // eslint-disable-next-line no-restricted-globals
      const left = (screen.width - width) / 2
      // eslint-disable-next-line no-restricted-globals
      const top = (screen.height - height) / 3

      window.open('/embed/login', '_blank', `
        scrollbars=0,
        width=${width},
        height=${height},
        top=${top},
        left=${left}
      `)
    },
  },
}
</script>
